{
  "name": "player",
  "version": "6.56.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 3000 --configuration development",
    "start:prod": "ng serve --port 3000 --configuration production",
    "build": "ng build && npm run gVersion && pnpm sentry:sourcemaps",
    "build:development": "ng build --configuration=development && npm run gVersion && pnpm sentry:sourcemaps",
    "build:production": "ng build --configuration=production && npm run gVersion && pnpm sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "gVersion": "node generateVersion.js",
    "serve": "serve -s dist/player/browser",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org admefy --project player ./dist/player/browser && sentry-cli sourcemaps upload --org admefy --project player ./dist/player/browser"
  },
  "private": true,
  "dependencies": {
    "@admefy/domain": "^7.1.0",
    "@admefy/jswebrtc": "^2.0.1",
    "@angular/animations": "^19.0.4",
    "@angular/common": "^19.0.4",
    "@angular/compiler": "^19.0.4",
    "@angular/core": "^19.0.4",
    "@angular/forms": "^19.0.4",
    "@angular/platform-browser": "^19.0.4",
    "@angular/platform-browser-dynamic": "^19.0.4",
    "@angular/router": "^19.0.4",
    "@angular/service-worker": "^19.0.4",
    "@ng-web-apis/common": "^4.11.1",
    "@ngrx/operators": "^18.1.1",
    "@ngrx/signals": "^18.1.1",
    "@sentry/angular": "^8.44.0",
    "@sentry/cli": "^2.39.1",
    "animate.css": "^4.1.1",
    "core-js": "^3.39.0",
    "hls.js": "^1.5.17",
    "ngxtension": "4.2.0",
    "plyr": "^3.7.8",
    "random-gradient": "^0.0.2",
    "randomcolor": "^0.6.2",
    "rxjs": "~7.8.1",
    "scheduler-polyfill": "^1.3.0",
    "semver": "^7.6.3",
    "socket.io-client": "^4.8.1",
    "tslib": "^2.8.1",
    "twemoji": "^14.0.2",
    "wavesurfer.js": "^7.8.11",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.5",
    "@angular/cli": "^19.0.5",
    "@angular/compiler-cli": "^19.0.4",
    "@types/jasmine": "~5.1.5",
    "@types/random-gradient": "^0.0.2",
    "@types/randomcolor": "^0.5.9",
    "@types/semver": "^7.5.8",
    "autoprefixer": "^10.4.20",
    "browserslist": "^4.24.2",
    "ctix": "^2.7.0",
    "jasmine-core": "~5.5.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.49",
    "prettier": "^3.4.2",
    "serve": "^14.2.4",
    "tailwindcss": "^3.4.16",
    "typescript": "~5.7.2"
  },
  "packageManager": "pnpm@9.15.0+sha512.76e2379760a4328ec4415815bcd6628dee727af3779aaa4c914e3944156c4299921a89f976381ee107d41f12cfa4b66681ca9c718f0668fa0831ed4c6d8ba56c"
}
